<template>
  <tr :style="rowStyle" @click="openEtItemDialog(item)">
    <td
      v-for="header in headers"
      :key="header.id"
      :style="getCellStyle(header)"
    >
      <v-icon v-if="header.fieldType  === 'BOOLEAN'">
        {{ item[header.alias] ? 'mdi-check' : 'mdi-close' }}
      </v-icon>
      <div
        v-else
        class="d-flex"
        style="max-height: 126px!important; overflow: hidden"
      >
        <span
          :class="header.hasAdditionalString ? 'simple-link pointer' : ''"
          class="full-width"
          @click="e =>openLink(header, item, e)"
          v-html="getItemText(header, item)"
        />
      </div>
    </td>
    <slot name="additional-column"/>
  </tr>
</template>

<script>
import { getStyleForRow, getStyleFromHeader } from '@/components/et/view-settings/utils'
import moment from 'moment/moment'

export default {
  name: "EtViewerItem",
  props: {
    item: Object,
    headers: Array,
    rules: Array
  },
  methods: {
    getItemText (header, item) {
      let result
      if (header.fieldType === 'DATE') {
        result = moment(item[header.alias], 'YYYY-MM-DD', true).isValid()
          ? moment(item[header.alias], 'YYYY-MM-DD').format('DD.MM.YYYY')
          : item[header.alias] || ''
      } else {
        result = item[header.alias] || ''
      }
      if (header.hasAdditionalString && header.additionalString) {
        result = header.additionalString + result
      }
      return result
    },
    openEtItemDialog (item) {
      this.$emit('openEtiItemDialog', item)
    },
    getCellStyle (header) {
      return getStyleFromHeader(header, this.rules, this.item[header.alias])
    },
    openLink (header, item, e) {
      if (header.hasAdditionalString) {
        window.open(this.getItemText(header, item), '_blank')
        e.stopPropagation()
        e.preventDefault()
      } else if (window.getSelection().toString()) {
        e.stopPropagation()
        e.preventDefault()
      }
    },
  },
  computed: {
    rowStyle () {
      return getStyleForRow(this.rules, this.headers, this.item)
    }
  }
}
</script>
