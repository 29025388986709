<template>
  <div>
    <et-item
      v-for="header in sortedHeaders"
      :key="header.id"
      v-model:value="item[header.alias]"
      :editable="editable"
      :header="header"
      :relations="getRelations(header)"
      :shortMode="shortMode"
      :table-id="et.id"
    />
  </div>
</template>
<script>

import EtItem from '@/components/et/utils/EtItem.vue'

export default {
  name: 'EtItemCard',
  props: {
    et: Object,
    item: Object,
    shortMode: {
      type: Boolean,
      default: false
    }
  },
  components: { EtItem },
  methods: {
    getRelations (header) {
      if (this.et.relations) return this.et.relations.filter(relation => relation.mainHeader.id === header.id)
    }
  },
  computed: {
    sortedHeaders () {
      return this.et.headers.sort((prev, next) => prev.showIndex - next.showIndex)
    },
    primaryHeader () {
      return this.et.headers.find(el => el.isPrimaryTableKey)
    },
    editable () {
      return !!this.primaryHeader ||
        !!this.et.primaryTableName
    }
  }
}
</script>
