<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    :width="shortMode ? 700 : 900"
    persistent
  >
    <v-card :height="shortMode ? '70vh' : '90vh'" class="d-flex flex-column">
      <v-tabs
        v-show="et.relations.length > 0 && !shortMode"
        v-model="tab"
        bg-color="primary"
        class="mt-2 flex-grow-0 "
        density="compact"
        fixed-tabs
      >
        <v-tab value="fields">{{ $t('fields') }}</v-tab>
        <v-tab
          value="related"
        >
          {{ $t('relatedTables') }}
        </v-tab>
      </v-tabs>

      <v-card-title v-show="et.relations.length === 0 || shortMode">
        {{ et.name }}
      </v-card-title>

      <v-card-text class="overflow-y-auto flex-grow-1">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <et-item-card
              :et="et"
              :item="item"
              :shortMode="shortMode"
            />
          </v-tab-item>
          <v-tab-item v-if="!shortMode">
            <related-info-card
              :et="et"
              :item="item"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-btn v-if="isAuthenticated" color="black" outlined @click="print">{{
                $t('button.print')
              }}
            </v-btn>
            <v-btn
              v-if="isAuthenticated && (setAbility('MODERATOR') || setAbility('ADMIN'))"
              class="ml-2"
              color="red"
              outlined
              @click="$refs.confirmationDialog.open()"
            >
              {{ $t('button.remove') }}
            </v-btn>
            <v-btn v-if="isAuthenticated" class="ml-2" color="primary" outlined @click="updateRow(et, item)">{{
                $t('button.save')
              }}
            </v-btn>
          </v-tab-item>
        </v-tabs-items>

      </v-card-actions>
    </v-card>
    <confirmation-dialog ref="confirmationDialog" :title="$t('deleteConfirmation')" @confirm="deleteRow(et, item)"/>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/et-item/messages'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import { etServiceMixin } from '@/mixins/et-service-mixin'
import RelatedInfoCard from '@/components/et/utils/RelatedInfoCard.vue'

export default {
  name: 'EtItemDialog',
  components: { RelatedInfoCard, ConfirmationDialog, EtItemCard: () => import('@/components/et/EtItemCard.vue') },
  mixins: [etServiceMixin],
  props: {
    shortMode: {
      type: Boolean,
      default: false
    }
  },
  i18n: { messages },
  data: () => ({
    dialog: false,
    item: null,
    et: null,
    tab: 'fields'
  }),
  methods: {
    open (et, item) {
      this.dialog = true
      this.et = JSON.parse(JSON.stringify(et))
      this.item = JSON.parse(JSON.stringify(item))
    },
    close () {
      this.dialog = false
      this.tab = 'fields'
      this.et = null
      this.item = null
    },
    print () {
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      let rows = []
      this.et.headers.forEach(header => {
        rows.push(`<div><b>${header.fieldName}: </b>${this.item[header.alias]}</div>`)
      })
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
            <style>
                div {padding: 10px}
            </style>
        </head>
        <body>
          ${rows.join('')}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    }
  }
}
</script>
