<template>
  <v-menu
    id="FeatureSelector"
    v-model="menu"
    :position-x="position.x"
    :position-y="position.y"
    absolute
    style="z-index: 2000"
  >
    <v-list>
      <v-list-item
        v-for="feature in featureList"
        dense
        @click="showFeature(feature)"
      >
        <v-list-item-avatar>
          <v-icon small>layers</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ feature.type === 'PKK' ? feature.attrs.id : feature.layer.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ feature.type === 'PKK' ? 'PKK' : feature.template && feature.template.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'FeatureSelector',
  data: () => ({
    menu: false,
    position: { x: 0, y: 0 },
    featureList: [],
    multiselect: false
  }),
  methods: {
    open (featureList, position, multiselect = false) {
      this.position = { ...position }
      this.featureList = { ...featureList }
      this.multiselect = multiselect
      this.menu = true
    },
    showFeature (feature) {
      this.$emit('show', {feature, multiselect: this.multiselect})
    }
  }
}
</script>
