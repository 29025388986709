<template>
  <div>
    <div v-show="!displayXS || isDataListView" class="header-data-panel">

      <div
        class="hidden-xs-only float-right"
        style="padding-top: 2px"
      >
        <slot name="actions"/>
      </div>

      <filters-panel
        v-if="!displayXS"
        :filter-list="filters"
        :sorting="sorting"
        @change="filterData"/>

      <v-card-actions v-if="displayXS" class="pa-0">
        <v-btn class="px-1 mb-1 d-inline-block" icon large @click="$router.push(`/project/${selectedProject.id}/home`)">
          <v-icon>navigate_before</v-icon>
        </v-btn>
        <v-spacer/>
        <filters-panel :filter-list="filters" :sorting="sorting" class="d-inline-block" @change="filterData"/>
      </v-card-actions>

    </div>

    <div v-show="!displayXS || isDataListView" :style="widthTable" class="doc-list-panel">
      <div v-show="panelExpanded" style="border-right: solid 1px #d6d2d2">
        <v-card-actions class="py-0">
          <div class="body-1 ml-2" style="color: dimgray">
            {{ $t(headerOfPage) }}
            <v-chip label outlined small>{{ totalElements }}</v-chip>
          </div>
          <v-spacer/>
          <v-btn :disabled="isDataListView" icon @click="panelExpanded = !panelExpanded">
            <v-icon v-show="!isDataListView">{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-simple-table
          v-infinite-scroll="loadData"
          class="data-list-table"
          infinite-scroll-disabled="busy" infinite-scroll-distance="10"
          v-bind:style="isDataListView ? '' : 'width: 280px !important'"
        >
          <tbody>
          <abstract-data-row
            v-for="(item, index) in items"
            :additionalField="additionalField"
            :class="index % 2 == 0 ? 'accent-row' : ''"
            :isDataListView="isDataListView"
            :item="item"
            :selectable="selectable"
            :selectedDataId="selectedDataId"
            @open="open"
            @selectHandler="selectHandler"
            @updated="filterData"
          />
          </tbody>
        </v-simple-table>
      </div>
      <div v-show="!panelExpanded"
           style="cursor: pointer; border-right: solid 1px #d6d2d2; min-height: calc(100vh - 50px)"
           @click="panelExpanded = true">
        <v-btn class="mb-2" icon>
          <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
        </v-btn>
        <div class="body-1" style="transform: rotate(90deg); white-space: nowrap">{{ $t(headerOfPage) }}</div>
      </div>
    </div>
    <router-view :style="panelExpanded ? 'padding-left: 280px;' : 'padding-left: 36px;'" class="doc-view-panel"/>
    <slot/>

    <abstract-data-details-dialog ref="itemDetailsDialog" @save="save"/>
  </div>
</template>

<script>
import { filterMixin } from '@/mixins/filter-mixin'
import FiltersPanel from '../filters/FiltersPanel'
import { EventBus } from '@/event-bus'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import messages from '../../shared-locale/project/messages'
import AbstractDataRow from '@/components/utils/AbstractDataRow'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog'

export default {
  name: 'AbstractDataList',
  mixins: [filterMixin, displayServiceMixin],
  components: {
    AbstractDataDetailsDialog,
    AbstractDataRow,
    FiltersPanel
  },
  i18n: { messages: messages },
  props: {
    type: {
      type: String,
      default: ''
    },
    view: {
      type: String,
      default: ''
    },
    additionalField: {
      type: String,
      default: null
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    panelExpanded: true,
    busy: false,
    cancel: undefined,
    items: [],
    selected: {
      name: ''
    },
    page: 1,
    totalElements: 0
  }),
  computed: {
    headerOfPage () {
      return this.$route.matched[1].meta.page
    },
    selectedDataId () {
      return this.$route.params.id
    },
    widthTable () {
      let style = { width: '', left: '' }

      if (this.isDataListView) {
        style.width = '100%'
      } else if (this.panelExpanded) {
        style.width = '280px'
      } else {
        style.width = '36px'
      }
      const projectPanelExpanded = this.$store.state.projectPanelExpanded

      if (!this.isDataListView && projectPanelExpanded) {
        style.left = '280px'
      } else if (!this.isDataListView && !projectPanelExpanded) {
        style.left = '34px'
      }
      return style

    },
    routeName () {
      return this.$route.name
    },
    isDataListView () {
      return this.routeName === this.view
    },
    searchRequest () {
      let criteria = this.filters
        .map(filter => {
          if (filter.model.value instanceof Array) {
            let values = filter.model.value.map(v => v.id)
            return {
              name: filter.name,
              value: values
            }
          } else {
            return {
              name: filter.name,
              value: filter.model.value
            }
          }
        })

      return {
        page: this.page,
        rowsPerPage: 10,
        sortBy: this.sorting.model.value.sortBy,
        desc: this.sorting.model.value.desc,
        criteria: criteria
          .filter(c => {
            if (c.value instanceof Array) {
              return c.value.length
            } else {
              return c.value
            }
          })
      }
    }
  },
  mounted () {
    this.loadData()
    EventBus.$on('loadData', () => this.filterData())
  },
  watch: {
    items (val) {
      this.$emit('sync-items', val)
    },
    panelExpanded (val) {
      EventBus.$emit('frameResize')
      if (this.isDataListView && !val) {
        this.panelExpanded = true
      }
    },
    isDataListView (val) {
      if (val) {
        this.panelExpanded = true
      }
    },
    selectedProject (val) {
      if (val) this.loadData()
    }
  },
  methods: {
    open (item, event) {
      this.panelExpanded = false
      this.selected = item
      EventBus.$emit('openData')
      this.openInNewTab(item, event)
    },
    save (item) {
      this.$emit('save', item)
    },
    filterData () {
      this.page = 1
      this.items = []
      this.loadData()
    },
    loadData () {
      const CancelToken = this.$axios.CancelToken
      this.busy = true
      let self = this
      if (this.cancel) {
        this.cancel()
      }
      let requestParam = {
        type: this.type,
        projectId: this.selectedProject.id
      }
      this.$axios
        .post('/data/find-pageable-search-result', this.searchRequest,
          {
            params: requestParam,
            cancelToken: new CancelToken(function executor (c) {
              self.cancel = c
            })
          })
        .then(response => {
          if (response.data.content.length) {
            this.items.push(...response.data.content)
            this.page++
            this.busy = false
          }
          this.totalElements = response.data.totalElements
        })
        .catch(thrown => {
          if (this.$axios.isCancel(thrown)) {
            console.error('Request canceled', thrown.message)
          } else {
            thrown ? console.error(thrown.toString()) : console.error('Unknown exception')
          }
        })
    },
    createData () {
      this.$refs.itemDetailsDialog.createData()
    },
    hideDialog () {
      this.$refs.itemDetailsDialog.hideDialog()
    },
    selectHandler () {
      let selectedItems = this.items.filter(item => item.selected)
      this.$emit('update:selected-items', selectedItems)
    }
  }
}
</script>
