<template>
  <v-dialog v-if="dialog" v-model="dialog" width="400">
    <v-card>
      <v-card-title class="py-2">
        {{ $t('button.export') }}
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-list class="d-flex flex-column">

          <p class="font-italic subtitle-1 mx-5 my-0 ">{{ $t('onlyProps') }}</p>
          <v-btn
            v-for="templatePoiList in poisByTemplate"
            :loading="isButtonLoading(templatePoiList[0].template, false)"
            class="px-2 mt-2 mx-4 justify-start"
            dense
            outlined
            style="overflow: hidden"
            text
            @click="exportProperties(false, templatePoiList)"
          >
            <layer-poi-badge :template="templatePoiList[0].template"/>
            {{ templatePoiList[0].template.name }}
          </v-btn>

          <p class="font-italic subtitle-1 mx-5 mb-0 mt-3">{{ $t('propsWithGeometry') }}</p>
          <v-btn
            v-for="templatePoiList in poisByTemplate"
            :loading="isButtonLoading(templatePoiList[0].template, true)"
            class="px-2 mt-2 mx-4 justify-start"
            dense
            outlined
            style="overflow: hidden"
            text
            @click="exportProperties(true, templatePoiList)"
          >
            <layer-poi-badge :template="templatePoiList[0].template"/>
            {{ templatePoiList[0].template.name }}
          </v-btn>

          <p class="font-italic subtitle-1 mx-5 mb-0 mt-3">{{ $t('geoJson') }}</p>
          <v-btn
            :loading="isLoadingGeoJson"
            class="px-2 my-2 mx-4 justify-start"
            dense
            outlined
            text
            @click="exportGeoJson"
          >
            <v-icon class="mr-3">mdi-file-code</v-icon>
            {{ $t('geoJson') }}
          </v-btn>

        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/mass-actions/messages'
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge.vue'

export default {
  name: "LpsExportDialog",
  components: { LayerPoiBadge },
  data: () => ({
    dialog: false,
    pois: null,
    loadingTemplates: [],
    isLoadingGeoJson: false
  }),
  i18n: { messages },
  methods: {
    open (pois) {
      this.pois = pois
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    exportProperties (withGeometry = false, pois) {
      const lpIds = pois.map(it => it.id)
      const name = pois[0].template.name
      this.loadingTemplates.push({ id: pois[0].template.id, withGeometry })
      this.$axios
        .post(`layer-poi/export-lps/xlsx${withGeometry ? '-with-geometry' : ''}`, lpIds, {
          responseType: 'arraybuffer'
        })
        .then(({ data }) => {
          let url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          link.download = `${name} ${this.formatDateDIN(new Date(Date.now()))}.xlsx`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => {
          setTimeout(() => {
            this.loadingTemplates = this.loadingTemplates.filter(el => !(el.id === pois[0].template.id && el.withGeometry === withGeometry))
          }, 500)
        })
    },
    exportGeoJson () {
      const lpIds = this.pois.map(it => it.id)
      this.isLoadingGeoJson = true
      this.$axios
        .post(`layer-poi/geo-json-by-lps`, lpIds)
        .then(({ data }) => {
          let geojson = JSON.stringify(data)
          let url = window.URL.createObjectURL(new Blob([geojson]))
          let link = document.createElement('a')
          link.href = url
          let name = `${lpIds.length} ${this.$t('objects')}. ${this.formatDateDIN(new Date(Date.now()))}`
          link.download = `${name}.geojson`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => {
          setTimeout(() => {
            this.isLoadingGeoJson = false
          }, 500)
        })
    },
    isButtonLoading (template, withGeometry) {
      return !!this.loadingTemplates.find(el => el.id === template.id && el.withGeometry === withGeometry)
    }
  },
  computed: {
    poisByTemplate () {
      if (!this.pois) return
      return this.pois.reduce((prev, it) => {
        const i = prev.findIndex(group => group.every(el => el.template.id === it.template.id))
        i >= 0 ? prev[i].push(it) : prev.push([it])
        return prev
      }, [])
    }
  }
}
</script>
