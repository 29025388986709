<template>
  <v-autocomplete
    v-model="filter[getIndexOfFilter(header.alias)].value"
    :items="selectStrings"
    :loading="false"
    :no-data-text="$t('noResults')"
    :search-input.sync="inputValue"
    class="mt-1"
    clearable
    dense
    hide-details
    multiple
    outlined
    @change="value => changeSelected(value)"
  >
    <template v-slot:selection>
      <div></div>
    </template>
    <template v-slot:prepend-inner>
      <v-chip v-if="selected.length > 0" small>
        {{ selected.length }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import messages from '@/componet-locale/et-filter/messages'

export default {
  name: 'ListHeaderFilter',
  i18n: { messages },
  props: {
    tableId: Number,
    header: Object,
    filter: Array,
    getIndexOfFilter: Function
  },
  data: () => ({
    selectItems: [],
    inputValue: '',
    selected: []
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.updateAutocomplete()
    },
    updateAutocomplete () {
      this.$axios
        .post(this.isAuthenticated ? 'et/query/autocomplete' : 'public-data/et-autocomplete', {
          tableId: this.tableId,
          header: this.header,
          value: this.inputValue
        })
        .then(({ data }) => {
          this.selectItems = data
        })
        .catch(er => console.error(er.message))
    },
    changeSelected (selected) {
      this.selected = selected
    }
  },
  computed: {
    selectStrings () {
      const res = []
      res.push(...this.selected)
      if (this.header.searchOptions && this.header.searchOptions.length > 0) {
        res.push(...this.header.searchOptions
          .split(',')
          .map(op => op.trimStart().trimEnd())
        )
      }
      res.push(...this.selectItems.map(el => el.value))
      return res
        .filter((function (item, pos) {
          return res.indexOf(item) === pos;
        }))
        .filter(el => el !== null)
    }
  },
  watch: {
    inputValue (val) {
      if (val !== null) {
        this.updateAutocomplete()
      }
    }
  }
}
</script>

