<template>
  <div>
    <vl-layer-vector
      v-if="addressList.length"
      :z-index="6"
    >
      <vl-source-vector :features="addressList"/>
      <vl-style-box>
        <vl-style-icon
          :anchor="[0.5, 0.4]"
          :scale="0.2"
          src="/image/baseline_location.png"
        />
        <vl-style-stroke
          :width="5"
          color="red"
        />
      </vl-style-box>
    </vl-layer-vector>

    <portal ref="map-search-portal" v-slot="{ goToPoiListener }" to="map-search">
      <map-search-field
        v-model="filter"
        :loading="loading"
        @clear="clearList"
        @search="search"
        @tune="tune = !tune"
      />
      <map-search-filter
        v-if="tune"
        v-model="filter"
        :layers="layers"
        @search="search"
      />
      <layer-poi-search-result
        v-for="item in layerPoiList"
        :item="item"
        :search-field="filter.searchField"
        @show="poi => callShowPoi(poi, goToPoiListener)"
      />
      <pkk-search-result
        v-for="feature in pkkFeatures"
        :feature="feature"
        @showBbox="showBbox"
        @showPkkFeature="pkkFeature => callShowPkkFeature(pkkFeature, goToPoiListener)"
      />
      <zws-search-result
        v-for="item in entries"
        :item="item"
      />
      <address-search-result
        :addressList="addressList"
        @show="showBbox"
      />
    </portal>
  </div>
</template>

<script>
import MapSearchMixin from '@/components/map/map-search/MapSearchMixin'
import PkkSearchResult from '@/components/map/map-search/search-result/PkkSearchResult.vue'

export default {
  components: { PkkSearchResult },
  mixins: [MapSearchMixin],
  methods: {
    callShowPoi (poi, goToPoiListener) {
      this.show(poi)
      if (goToPoiListener) goToPoiListener()
    },
    callShowPkkFeature (pkkFeature, goToPoiListener) {
      this.showPkkFeature(pkkFeature)
      if (goToPoiListener) goToPoiListener()
    }
  }
}
</script>
