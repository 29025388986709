import LayerPoiSearchResult from '@/components/map/map-search/search-result/LayerPoiSearchResult.vue'
import MapSearchFilter from '@/components/map/map-search/MapSearchFilter.vue'
import MapSearchField from '@/components/map/map-search/MapSearchField.vue'
import ZwsSearchResult from '@/components/map/map-search/search-result/ZwsSearchResult.vue'
import AddressSearchResult from '@/components/map/map-search/search-result/AddressSearchResult.vue'
import messages from '@/componet-locale/map-search/messages'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'
import { transformExtent } from 'ol/proj'

export default {
  name: 'MapSearch',
  components: { LayerPoiSearchResult, MapSearchFilter, MapSearchField, ZwsSearchResult, AddressSearchResult },
  i18n: { messages },
  props: ['layers', 'extent', 'view'],
  data: () => ({
    layerPoiList: [],
    entries: [],
    pkkFeatures: [],
    addressList: [],
    tune: false,
    loading: false,
    filter: {
      searchField: '',
      selectedLayer: '',
      selectedMode: '',
      selectedType: '',
      onlyByID: false,
      onlyByAddress: false,
      exactMatch: false
    },
    showResults: false,
    layerPoiTemplates: []
  }),
  methods: {
    async search (e) {
      if (e) this.showResults = true
      this.loading = true
      this.entries = []
      this.addressList = []
      let coordinates = this.getCoordinates()
      if (coordinates) {
        this.show(coordinates, true)
        this.loading = false
        return
      }

      if (!this.selectedLayer || this.onlyByAddress) {
        this.searchByAddress()
        if (this.onlyByAddress) {
          this.loading = false
          return
        }
      }
      this.findByLP()

      await this.searchByPkk()

      if (!this.zwsLayers.length) {
        this.loading = false
        return
      }
      await this.searchByZwsLayers()
    },
    async searchByZwsLayers () {
      let { selectedType, selectedMode, selectedLayer, searchField, onlyByID } = this.filter
      let items = []
      let typeId = selectedType ? selectedType.typeId : ''
      let modeId = selectedMode ? selectedMode.index : ''
      try {
        if (selectedLayer) {
          items = await ZwsCommandBuilder.search(selectedLayer, searchField, onlyByID, typeId, modeId)
        } else {
          for (let layer of this.zwsLayers) {
            let result = await ZwsCommandBuilder.search(layer, searchField, onlyByID)
            items = items.concat(result)
          }
        }
      } catch (e) {
        this.loading = false
      }
      this.loading = false
      if (items.length === 1) {
        this.show(items[0])
      } else if (items.length === 0 && this.layerPoiList.length === 0) {
        EventBus.$emit('showInfoMessage', this.$t('noDataByRequest'))
      }
      items.forEach(item => {
        if (item.row.typeid) {
          let type = item.layer.typeList.find(type => type.typeId === item.row.typeid.value)
          let mode = type.modes.find(mode => mode.index === item.row.modeid.value)
          item.icon = mode ? mode.image : ''
        }
      })
      this.entries = items
    },
    async searchByPkk () {
      this.pkkFeatures = []
      if (this.pkkLayers.length === 0) return
      if (this.filter.searchField) {
        await this.$axios.get(`https://apipkk.ru/api/features/1?text=${this.filter.searchField}&limit=10&skip=0&tolerance=4`, {
          withCredentials: false
        })
          .then(res => res.data.features)
          .then(features => {
            if (!features) throw new Error("Response has no pkk features")
            features.forEach(feature => this.pkkFeatures.push(feature))
          })
          .catch(er => console.info(er))
      }
    },
    getCoordinates () {
      let field = this.filter.searchField ? this.filter.searchField.replaceAll(' ', '') : ''
      let array = field
        .split(',')
        .map(item => {
          return parseFloat(item)
        })

      if (array.length === 2
        && !Number.isNaN(array[0]) && !Number.isNaN(array[1])
        && array[0] > -180 && array[0] < 180 && array[1] > -90 && array[1] < 90) {
        return [array[0], array[1]]
      }
    },
    show (item, byCoordinates = false) {
      this.$emit('show', item, byCoordinates)
    },
    showBbox (bbox) {
      this.$emit('showBbox', bbox)
    },
    showPkkFeature (pkkFeature) {
      this.$emit('showPkkFeature', pkkFeature)
    },
    clear () {
      this.clearList()
      this.filter = {
        searchField: this.filter.searchField ? this.filter.searchField : '',
        selectedLayer: '',
        selectedMode: '',
        selectedType: '',
        onlyByID: false,
        onlyByAddress: false
      }
      this.search()
    },
    clearList () {
      this.filter.searchField = this.filter.searchField ? this.filter.searchField : ''
      this.entries = []
      this.addressList = []
      this.pkkFeatures = []
    },
    searchByAddress (byExtent = true) {
      if (!this.view.$view) return
      let displayExtent = transformExtent(this.view.$view.calculateExtent(), 'EPSG:3857', 'EPSG:4326')

      let extent

      let maxExtent = this.extent ? [
        this.extent[0] < -528 ? -528 : this.extent[0].toFixed(7),
        this.extent[1] < -90 ? -90 : this.extent[1].toFixed(7),
        this.extent[2] > 551 ? 551 : this.extent[2].toFixed(7),
        this.extent[3] > 90 ? 90 : this.extent[3].toFixed(7)
      ] : [-528, -90, 551, 90]

      if (byExtent) {
        extent = [
          displayExtent[0] > maxExtent[0] ? displayExtent[0].toFixed(7) : maxExtent[0],
          displayExtent[1] > maxExtent[1] ? displayExtent[1].toFixed(7) : maxExtent[1],
          displayExtent[2] < maxExtent[2] ? displayExtent[2].toFixed(7) : maxExtent[2],
          displayExtent[3] < maxExtent[3] ? displayExtent[3].toFixed(7) : maxExtent[3]
        ]
      } else {
        extent = maxExtent
      }
      let url = `https://nominatim.openstreetmap.org/search?q=${this.filter.searchField}&countrycodes=ru&format=geojson&addressdetails=1&viewbox=${extent}&bounded=1`

      fetch(url)
        .then(res => res.json())
        .then(response => {
          this.addressList = response.features
          if (!this.addressList.length && byExtent) {
            this.searchByAddress(false)
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => (this.loading = false))
    },
    findByLP () {
      let layer
      if (this.filter.selectedLayer && this.filter.selectedLayer.type === 'LAYER_POI') {
        layer = this.filter.selectedLayer
      }
      let layerIdList = layer ? [layer.id] : this.lpLayers.map(it => it.id)
      let searchField = this.filter.exactMatch ? this.filter.searchField : `%${this.filter.searchField}%`
      this.loading = true
      this.$axios
        .get('layer-poi/find-by-name', {
          params: {
            layerIds: layerIdList.toString(),
            name: searchField
          }
        })
        .then(res => {
          this.layerPoiList = res.data.lps
          this.layerPoiTemplates = res.data.templates
        })
        .catch(error => {
          console.error('Произошла ошибка при попытке поиска по объектам LP')
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    collapse () {
      this.showResults = false
      this.tune = false
    },
    expand () {
      this.showResults = true
    },
    getTemplateById(id){
      return this.layerPoiTemplates.filter(t => t.id === id)[0]
    }
  },
  created () {
    this.findByLP()
    EventBus.$on("refreshMap", () => this.search())
  },
  watch: {
    'filter.searchField' () {
      this.clearList()
    },
    'filter.selectedLayer' () {
      this.filter.selectedType = ''
      this.filter.selectedMode = ''
    },
    'filter.selectedType' () {
      this.selectedMode = ''
    },
    layers () {
      this.clear()
    }
  },
  computed: {
    zwsLayers () {
      return this.layers.filter(layer => layer.type === 'ZWS')
    },
    lpLayers () {
      return this.layers.filter(layer => layer.type === 'LAYER_POI' && layer.visible)
    },
    pkkLayers () {
      return this.layers.filter(layer => layer.type === 'PKK' && layer.visible)
    }
  }
}
