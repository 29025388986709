<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="600">
    <v-card style="height: 90vh">
      <v-card-title>
        {{ $t('objectsSelected') }}: {{ selectedFeaturesCount }}
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="featuresGroupByLayers" class="overflow-y-auto py-0" style="height: calc( 90vh - 114px )">
        <div v-for="layer in featuresGroupByLayers" :key="layer.id">
          <div class="d-flex align-center py-2 title">
            <v-simple-checkbox
              :ripple="false"
              :value="layer.features.every(el => el.selected)"
              class="ma-0"
              @input="layerChangeSelected(layer)"
            />
            <div class="ml-2 d-flex full-width">
              <span class="subtitle-1">{{ layer.name }}: {{ getLayerSelectedFeaturesCount(layer) }}</span>
            </div>
          </div>
          <div
            v-for="feature in layer.features"
            :key="feature.id"
            class="d-flex flex-nowrap align-center ml-4"
          >
            <v-checkbox
              v-model="feature.selected"
              class="ma-0 pa-0"
              dense
              hide-details
            />
            <v-list-item
              class="d-flex flex-nowrap align-center px-5"
              @click="openLayerPoiDialog(feature)"
            >
              {{ getNameOfLayerPoi(feature) }} [{{ feature.id }}]
              <v-spacer/>
              <layer-poi-badge :template="feature.template"/>
            </v-list-item>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="px-4 elevation-2">
        <v-btn
          :disabled="selectedFeaturesCount === 0"
          color="red"
          outlined
          @click="$refs.deleteFeaturesConfirmationDialog.open({name: $t('deleteObjects')})"
        >
          {{ $t('button.remove') }}
        </v-btn>
        <v-btn
          :disabled="selectedFeaturesCount === 0"
          class="ml-2"
          color="primary"
          outlined
          @click="openLpsExportDialog"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t('button.export') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          :disabled="selectedFeaturesCount === 0"
          color="primary"
          outlined
          @click="edit"
        >
          {{ $t('button.edit') }}
        </v-btn>
        <v-btn
          :disabled="selectedFeaturesCount === 0"
          outlined
          @click="openPrintTemplateSelectorDialog"
        >
          {{ $t('button.print') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <layer-poi-dialog
      ref="layerPoiDialog"
      :actions-toolbar="false"
      :map-widget="false"
      :readonly-mode="true"
    />
    <confirmation-dialog
      ref="deleteFeaturesConfirmationDialog"
      :title="$t('doYouReallyWant')"
      @confirm="remove"
    />
    <lps-export-dialog ref="lpsExportDialog"/>
  </v-dialog>
</template>

<script>
import { getNameOfLayerPoi } from '../../layer-poi/layer-poi-service'
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge.vue'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog.vue'
import messages from '@/componet-locale/mass-actions/messages'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import { EventBus } from '@/event-bus'
import LpsExportDialog from '@/components/map/helpers/LpsExportDialog.vue'

export default {
  name: 'FeatureSelectorDialog',
  components: { LpsExportDialog, ConfirmationDialog, LayerPoiDialog, LayerPoiBadge },
  i18n: { messages },
  data: () => ({
    dialog: false,
    features: [],
    featuresGroupByLayers: []
  }),
  methods: {
    getNameOfLayerPoi,
    init (features) {
      this.features = features

      features.forEach(feature => {
        if (!this.featuresGroupByLayers.find(l => l.id === feature.layer.id)) {
          this.featuresGroupByLayers.push({ id: feature.layer.id, name: feature.layer.name, features: [] })
        }
        this.featuresGroupByLayers[this.featuresGroupByLayers.indexOf(this.featuresGroupByLayers.find(layer => layer.id === feature.layer.id))]
          .features
          .push({ ...feature, selected: true })
      })
    },
    open (features) {
      this.dialog = true
      this.featuresGroupByLayers = []
      this.features = []
      this.init(features)
    },
    close () {
      this.dialog = false
    },
    openLayerPoiDialog (poi) {
      this.$refs.layerPoiDialog.open(poi)
    },
    remove () {
      const features = this.getSelectedFeatures()
      let ids = features.map(it => it.id).join(',')
      this.$axios
        .delete('layer-poi/list', {
          params: {
            ids: ids
          }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', `${this.$t('deletedObjects')} ${features.length}`)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => {
          this.$emit('update')
          this.close()
        })
    },
    getSelectedFeatures () {
      const features = []
      this.featuresGroupByLayers.forEach(layer => {
        features.push(...layer.features.filter(feature => feature.selected))
      })
      return features
    },
    openPrintTemplateSelectorDialog () {
      this.$emit('openPrintTemplateSelectorDialog', this.printTemplates)
      this.close()
    },
    edit () {
      this.$emit('edit', this.getSelectedFeatures())
      this.close()
    },
    layerChangeSelected (layer) {
      if (layer.features.every(el => el.selected)) {
        layer.features.forEach(el => el.selected = false)
      } else {
        layer.features.forEach(el => el.selected = true)
      }
    },
    getLayerSelectedFeaturesCount (layer) {
      return layer.features.filter(el => el.selected).length
    },
    openLpsExportDialog () {
      this.$refs.lpsExportDialog.open(this.getSelectedFeatures())
    }
  },
  computed: {
    printTemplates () {
      const templates = []
      const features = this.getSelectedFeatures()
      features.forEach(({ template }) => {
        template.printTemplates.forEach(printTemplate => {
          if (!templates.find(el => el.id === printTemplate.id)) {
            templates.push(printTemplate)
          }
        })
      })
      return templates
    },
    selectedFeaturesCount: {
      get () {
        let count = 0
        this.featuresGroupByLayers.forEach(l => {
          l.features.forEach(f => {
            f.selected ? count++ : null
          })
        })
        return count
      }
    }
  }
}
</script>
