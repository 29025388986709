<template>
  <div>
    <v-card-title>
      {{ $t('objectStyles') }}
      <v-spacer/>
      <v-btn
        color="light-gray"
        outlined
        @click="create"
      >
        <v-icon>add</v-icon>
        {{ $t('create') }}
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
    >
      <template v-slot:item="{ item }">
        <tr @click="open(item)">
          <td>{{ item.name }}</td>
          <td>{{ item.stroke }}</td>
          <td>{{ item.strokeWidth }}px</td>
          <td>{{ item.fill }}</td>
          <td>{{ 100 - Math.round(parseInt(item.fill.slice(-2), 16) * 100 / 256) }}%</td>
          <td v-if="item.icon" class="py-1">
            <v-img :src="item.icon" style="width: 100%;height: auto;max-width: 50px"/>
          </td>
          <td v-else>
            <div
              :style="{border: `2px solid ${item.stroke}`, height: '20px', backgroundColor: item.fill, width:'50%'}"></div>
          </td>
          <td>{{ item.imageScale }}</td>
          <td>{{ getImageAnchorName(item.imageAnchor) }}</td>
        </tr>
      </template>
    </v-data-table>
    <poi-style-dialog ref="poiStyleDlg" @update="loadStyles"/>
  </div>
</template>

<script>
import PoiStyleDialog from '@/components/admin/poi-styles/PoiStyleDialog.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/poi-styles/messages'

export default {
  name: 'PoiStyles',
  components: { PoiStyleDialog },
  i18n: { messages },
  data: () => ({
    items: []
  }),
  created () {
    this.loadStyles()
  },
  methods: {
    loadStyles () {
      this.$axios
        .get('layer-poi-style')
        .then(response => {
          this.items = response.data
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('errorGettingStyleData'))
        })
    },
    create () {
      this.$refs.poiStyleDlg.open()
    },
    open (item) {
      this.$refs.poiStyleDlg.open(item)
    },
    getImageAnchorName(anchor){
      if(anchor[0] === 0.9 && anchor[1] === 0.5){
        return this.$t('RIGHT')
      } else if (anchor[0] === 0.1 && anchor[1] === 0.5) {
        return this.$t('LEFT')
      } else if (anchor[0] === 0.5 && anchor[1] === 0.9) {
        return this.$t('TOP')
      } else if (anchor[0] === 0.5 && anchor[1] === 0.1) {
        return this.$t('BOTTOM')
      } else {
        return this.$t('CENTER')
      }
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('name'), value: 'name' },
        { text: this.$t('strokeColor'), value: 'stroke' },
        { text: this.$t('strokeWidth'), value: 'strokeWidth' },
        { text: this.$t('fillColor'), value: 'fill' },
        { text: this.$t('fillTransparency'), value: 'fillTransparency' },
        { text: this.$t('icon'), value: 'icon' },
        { text: this.$t('iconSize'), value: 'imageScale' }
      ]
    }
  }
}
</script>
