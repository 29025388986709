<template>
  <div>
    <v-dialog v-if="dialog" v-model="dialog" persistent width="700">
      <v-card style="height: 90vh">
        <v-card-title>
          {{ $t('objects') }}
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="overflow-y-auto py-0" style="height: calc( 90vh - 114px )">
          <div v-for="item in items" :key="item.layer.id">
            <div class="title pt-3">
              <span class="subtitle-1">{{ item.layer.name }}</span>
            </div>
            <v-list-item
              v-for="feature in item.features"
              :key="feature.id"
              @click="openFeatureDialog(item.layer, feature)"
            >
              <div v-if="item.layer.type === 'LAYER_POI'" class="d-flex full-width flex-nowrap align-center">
                <v-btn v-if="splitScreenMode" class="mr-2" color="primary" icon
                       @click.stop="emitOpenLayerPoi(feature.id)">
                  <v-icon>mdi-map-search</v-icon>
                </v-btn>
                {{ getNameOfLayerPoi(feature) }} [{{ feature.id }}]
                <v-spacer/>
                <layer-poi-badge :template="feature.template"/>
              </div>
              <div v-else-if="item.layer.type === 'WMS'">
                <v-btn v-if="splitScreenMode" class="mr-2" color="primary" icon
                       @click.stop="emitOpenWmsFeature(feature)">
                  <v-icon>mdi-map-search</v-icon>
                </v-btn>
                {{ feature.id }}
              </div>
            </v-list-item>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <layer-poi-dialog
      ref="layerPoiDialog"
      :map-widget="!splitScreenMode"
      actions-toolbar
      readonly-mode
    />
    <wms-feature-dialog
      ref="wmsFeatureDialog"
    />
  </div>
</template>

<script>
import { getNameOfLayerPoi } from '../../layer-poi/layer-poi-service'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog.vue'
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge.vue'
import WmsFeatureDialog from '@/components/map/geoserver/WmsFeatureDialog.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/features-by-et-item-dialog/messages'

export default {
  name: "FeaturesByEtItemDialog",
  components: { WmsFeatureDialog, LayerPoiBadge, LayerPoiDialog },
  i18n: { messages },
  data: () => ({
    dialog: false,
    items: []
  }),
  methods: {
    getNameOfLayerPoi,
    open (items) {
      this.items = items.reduce((acc, item) => {
        const layerIndex = acc.findIndex(el => el.layer.id === item.layer.id)
        if (layerIndex === -1) {
          return [...acc, item]
        } else {
          acc[layerIndex].features.push(...item.features)
          return acc
        }
      }, [])
      this.init()
    },
    init () {
      const featuresCount = this.items.reduce((acc, item) => acc + item.features.length, 0)
      if (featuresCount === 0) {
        EventBus.$emit('showInfoMessage', this.$t('objectNotFound'))
      } else if (featuresCount === 1 && this.splitScreenMode) {
        switch (this.items[0].layer.type) {
          case 'LAYER_POI':
            return this.emitOpenLayerPoi(this.items[0].features[0].id)
          case 'WMS':
            return this.emitOpenWmsFeature(this.items[0].features[0])
        }
      } else if (featuresCount === 1) {
        this.openFeatureDialog(this.items[0].layer, this.items[0].features[0])
      } else {
        this.dialog = true
      }
    },
    close () {
      this.dialog = false
    },
    async openFeatureDialog (layer, feature) {
      if (layer.type === 'LAYER_POI') {
        await this.$axios.get('layer-poi/find-by-id', {
          params: { id: feature.id }
        })
          .then(({ data }) => {
            this.$refs.layerPoiDialog.open(data)
          })
          .catch(() => EventBus.$emit('showInfoMessage', this.$t('objectNotFound')))
      } else if (layer.type === 'WMS') {
        await this.$refs.wmsFeatureDialog.open(feature, layer)
      }
    },
    emitOpenLayerPoi (lpId) {
      EventBus.$emit('openLayerPoi', lpId)
      this.close()
    },
    emitOpenWmsFeature (feature) {
      this.$axios(EventBus.$emit('showWmsFeature', feature))
      this.close()
    }
  },
  computed: {
    splitScreenMode () {
      return this.$route.matched[0].path.includes('split-screen')
    }
  }
}
</script>
