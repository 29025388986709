<template>
  <div>
    <v-select
      v-if="equalsMode"
      ref="stringHeaderFilter"
      v-model="filter[getIndexOfFilter(header.alias)].value"
      :items="selectStrings"
      :no-data-text="$t('noResults')"
      :open-on-clear="true"
      class="mt-1"
      dense
      hide-details
      multiple
      outlined
    >
      <template v-slot:append>
        <v-btn
          v-if="inputValue || filter[getIndexOfFilter(header.alias)].value[0]"
          elevation="0"
          icon
          small
          style="margin-top: -1px"
          @click="clear"
        >
          <v-icon color="primary">close</v-icon>
        </v-btn>
        <v-menu v-else style="z-index: 2001">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="onClickFilter" v-on="on">
              <v-icon small>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-list v-if="header.predicates.length > 0">
            <v-list-item
              v-for="predicate in header.predicates"
              :style="{backgroundColor: filter[getIndexOfFilter(header.alias)].predicate === predicate ? '#EEEEEE' : 'white'}"
              class="ma-0"
              dense
              @click="filter[getIndexOfFilter(header.alias)].predicate = predicate"
            >
              {{ $t(predicate) }}
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:selection="{item, index}">
        <v-chip v-if="index === 0" label>
          {{ filter[getIndexOfFilter(header.alias)].value.length }}
        </v-chip>
      </template>
      <template v-slot:prepend-item>
        <v-text-field
          v-model="inputValue"
          class="mx-3 pt-3 mb-2"
          clearable
          dense
          hide-details
          solo
          @click:clear="inputValue = ''"
        />
      </template>
    </v-select>
    <v-text-field
      v-else
      ref="stringHeaderFilter"
      v-model="filter[getIndexOfFilter(header.alias)].value[0]"
      class="mt-1"
      dense
      hide-details
      outlined
    >
      <template v-slot:append>
        <v-btn
          v-if="inputValue || filter[getIndexOfFilter(header.alias)].value[0]"
          elevation="0"
          icon
          small
          style="margin-top: -1px"
          @click="clear"
        >
          <v-icon color="primary">close</v-icon>
        </v-btn>
        <v-menu v-else style="z-index: 2001">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="onClickFilter" v-on="on">
              <v-icon small>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-list v-if="header.predicates.length > 0">
            <v-list-item
              v-for="predicate in header.predicates"
              :style="{backgroundColor: filter[getIndexOfFilter(header.alias)].predicate === predicate ? '#EEEEEE' : 'white'}"
              class="ma-0"
              dense
              @click="filter[getIndexOfFilter(header.alias)].predicate = predicate"
            >
              {{ $t(predicate) }}
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import messages from '@/componet-locale/et-filter/messages'
import { CONDITIONS } from '@/components/et/view-settings/utils'

export default {
  name: 'StringHeaderFilter',
  i18n: { messages },
  props: {
    tableId: Number,
    header: Object,
    filter: Array,
    getIndexOfFilter: Function
  },
  data: () => ({
    inputValue: '',
    items: []
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.updateAutocomplete()
    },
    updateAutocomplete () {
      this.$axios
        .post(this.isAuthenticated ? 'et/query/autocomplete' : 'public-data/et-autocomplete', {
          tableId: this.tableId,
          header: this.header,
          value: this.inputValue || ''
        })
        .then(({ data }) => {
          this.items = data
        })
        .catch(er => console.error(er.message))
    },
    clear () {
      this.filter[this.getIndexOfFilter(this.header.alias)].value = []
      this.inputValue = null
      this.updateAutocomplete()
      this.$emit('update')
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onClickFilter () {
      this.$refs.stringHeaderFilter.isFocused = false
      this.$nextTick(() => this.$refs.stringHeaderFilter.isFocused = false)
    }
  },
  computed: {
    CONDITIONS () {
      return CONDITIONS
    },
    selectStrings () {
      const res = [
        ...this.filter[this.getIndexOfFilter(this.header.alias)].value,
        ...this.items.map(el => el.value)
      ]
      return res
        .filter((function (item, pos) {
          return res.indexOf(item) === pos;
        }))
        .filter(el => el !== null)
    },
    equalsMode () {
      return this.filter[this.getIndexOfFilter(this.header.alias)].predicate === this.CONDITIONS.EQUAL
    }
  },
  watch: {
    inputValue (val) {
      if (val !== null) {
        this.updateAutocomplete()
      }
    }
  }
}
</script>
