<template>
  <div>
    <div class="view-data-page">
      <div class="navigation-panel" style="height: 100vh">
        <v-toolbar flat height="56">
          <div>{{ $t('button.printTemplates') }}</div>
          <v-spacer/>
          <v-icon @click="createNewTemplate">add</v-icon>
        </v-toolbar>
        <v-divider/>
        <v-list dense>
          <v-list-item
            v-for="item in templates"
            @click="selectTemplate(item)"
          >
            {{ item.name }}
          </v-list-item>
        </v-list>
      </div>
      <div class="router-view" style="margin-left: 280px; overflow-y: auto">
        <div v-if="selectedTemplate" id="doc-frame" class="pa-4" style="height: 100vh; overflow: auto;">
          <vue-file-toolbar-menu :content="toolbar" class="full-width"/>
          <print-template
            v-if="!editMode"
            :selectedTemplate="selectedTemplate"
          />
          <print-template-pages
            v-else
            ref="printTemplatePages"
            :selectedTemplate="selectedTemplate"
            @updateTemplate="updateTemplates"
          />
        </div>
        <div v-else class="pa-4">
          {{ $t('selectPrintTemplate') }}
        </div>
      </div>
    </div>
    <create-print-template-dialog
      ref="createPrintTemplateDialog"
      @createdTemplate="updateTemplates"
    />
    <confirmation-dialog
      ref="deleteTemplateDialog"
      :title="$t('button.remove')"
      @confirm="deleteTemplate"
    />
  </div>
</template>

<script>
import VueDocumentEditor from 'vue-document-editor'
import VueFileToolbarMenu from 'vue-file-toolbar-menu'
import PrintTemplateEditor from '@/components/admin/print-template/PrintTemplatePageEditorDialog.vue'
import MailTemplateEditor from '@/components/admin/mail-template/MailTemplateEditor.vue'
import PrintTemplate from '@/components/admin/print-template/PrintTemplate.vue'
import CreatePrintTemplateDialog from '@/components/admin/print-template/CreatePrintTemplateDialog.vue'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import messages from '@/componet-locale/print-template/messages'
import PrintTemplatePages from '@/components/admin/print-template/PrintTemplatePages.vue'

export default {
  name: 'PrintTemplates',
  i18n: { messages },
  components: {
    PrintTemplatePages,
    ConfirmationDialog,
    CreatePrintTemplateDialog,
    PrintTemplate,
    MailTemplateEditor,
    PrintTemplateEditor,
    VueDocumentEditor,
    VueFileToolbarMenu
  },
  data: () => ({
    selectedTemplate: null,
    templates: null,
    editMode: false
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$axios.get('print-template/get-all')
        .then(res => this.templates = res.data)
    },
    selectTemplate (template) {
      this.selectedTemplate = template
      this.editMode = false
    },
    updateTemplates (id = null) {
      this.$axios.get('print-template/get-all')
        .then(res => {
          this.templates = res.data
          if (id) {
            this.selectedTemplate = res.data.find(el => el.id === id)
          }
        })
    },
    createNewTemplate () {
      this.$refs.createPrintTemplateDialog.open()
    },
    deleteTemplate () {
      if (this.selectedTemplate) {
        this.$axios.delete('print-template', {
          params: {
            id: this.selectedTemplate.id
          }
        }).then(() => {
          this.selectedTemplate = null
          this.updateTemplates()
        })
      }
    }
  },
  computed: {
    toolbar () {
      return !this.editMode
        ? [
          {
            text: this.$t('button.print'), icon: 'print', click: (printTemplateName = "") => {
              const prevTitle = document.title
              document.title = this.selectedTemplate.name + " " + this.formatDateDIN(new Date(Date.now()))
              window.print()
              document.title = prevTitle
            }
          },
          {
            text: this.$t('button.remove'),
            icon:
              'delete',
            click:
              () => this.$refs.deleteTemplateDialog.open(this.selectedTemplate)
          }
          ,
          {
            text: this.$t('button.edit'),
            click:
              () => {
                this.editMode = !this.editMode
              }
          }
        ]
        : [
          {
            text: this.$t('button.back'),
            click: () => {
              this.editMode = !this.editMode
              this.updateTemplates(this.selectedTemplate.id)
            }
          },
          {
            text: this.$t('button.save'),
            click: () => {
              this.$refs.printTemplatePages.save()
            }
          }
        ]
    },
  }
}
</script>


