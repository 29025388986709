<template>
  <feature-card @close="$emit('close')">
    <template v-slot:name>
      {{ name }}
    </template>
    <template v-slot:info>
      <action-toolbar
        :actions="actions"
        class="px-3"
        @createIssue="createIssue"
        @edit="$emit('edit')"
        @remove="openConfirmationDialog"
        @share="share"
      />
      <v-tabs
        v-model="tab"
        bg-color="primary"
        class="mt-2"
        density="compact"
        fixed-tabs
      >
        <v-tab value="one">{{ $t('overview') }}</v-tab>
        <v-tab
          v-if="showDb"
          value="two"
          @click="() => $refs.dbFields ? $refs.dbFields.back() : ''"
        >
          {{ $('et') }}
        </v-tab>
      </v-tabs>
    </template>
    <template v-slot:fields>
      <v-tabs-items v-model="tab">

        <v-tab-item>
          <wfs-fields :feature="feature"/>
        </v-tab-item>

        <v-tab-item v-if="showDb">
          <feature-db-fields
            ref="dbFields"
            :infoFromDb="infoFromDb"
            @showBackBtn="updateShowBackBtn"
          />
        </v-tab-item>

      </v-tabs-items>
    </template>
    <template v-slot:actions>
      <v-tabs-items v-model="tab" class="full-width">
        <v-tab-item v-if="isAuthenticated">
          <div class="full-width d-flex flex-row">
            <v-spacer/>
            <v-btn
              color="primary"
              outlined
              @click="save"
            >
              {{ $t('button.save') }}
            </v-btn>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="full-width d-flex flex-row">
            <v-slide-y-reverse-transition>
              <v-btn
                v-show="showBackBtn"
                outlined
                @click="() => $refs.dbFields.back()"
              >
                <v-icon>mdi-backburger</v-icon>
              </v-btn>
            </v-slide-y-reverse-transition>
          </div>
        </v-tab-item>

        <v-tab-item>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <confirmation-dialog
      ref="removeConfirmationDialog"
      title="Вы действительно хотите удлаить объект"
      @confirm="remove"
    />

  </feature-card>
</template>

<script>
import FeatureCard from '@/components/map/FeatureCard'
import WfsFields from '@/components/map/geoserver/WfsFeatureFields'
import ActionToolbar from '@/components/utils/ActionToolbar'
import { EDIT, OPEN_ISSUES, REMOVE, SHARE } from '@/components/map/helpers/map-actions'
import { GML, WFS } from 'ol/format'
import { EventBus } from '@/event-bus'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import EtItemCard from '@/components/et/EtItemCard.vue'
import LayerPoiFields from '@/components/layer-poi/LayerPoiFields.vue'
import FeatureDbFields from '@/components/map/FeatureDbFields.vue'

export default {
  name: 'GeoServerFeatureCard',
  components: {
    FeatureDbFields,
    LayerPoiFields,
    EtItemCard,
    ConfirmationDialog,
    ActionToolbar,
    WfsFields,
    FeatureCard
  },
  props: { feature: Object },
  data: () => ({
    tab: 'one',
    infoFromDb: null,
    showBackBtn: false
  }),
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      const configs = await this.$axios.get('/layer/get-related-tables', {
        params: {
          layerId: this.feature.layer.id
        }
      })
        .then(res => res.data)
      const queryForm = []
      configs.forEach(config => {
        queryForm.push({
          etToLayerConfigId: config.id,
          searchValue: this.feature.values_[config.wfsPropertyName]
        })
      })
      this.$axios.post('et/select-for-wms-feature', queryForm)
        .then(res => this.infoFromDb = res.data)
    },
    edit () {
      this.$emit('editFeature')
    },
    openConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open({ name: this.name })
    },
    share () {
      // TODO impl
    },
    createIssue () {
      // TODO impl
    },
    refresh () {
      EventBus.$emit('refreshMap')
    },
    save () {
      let { layer } = this.feature
      let formatWFS = new WFS()
      let gml = new GML({
        featureNS: layer._ds.targetNamespace,
        featurePrefix: layer._ds.targetPrefix,
        featureType: layer._ds.typeName,
        srsName: 'EPSG:3857'
      })
      let node = formatWFS.writeTransaction(null, [this.feature], null, gml)
      let s = new XMLSerializer()
      let value = s.serializeToString(node)
      this.$axios
        .post(window.location.origin + '/geoserver/wfs', value, {
          headers: {
            'Content-Type': 'text/xml'
          },
          params: {
            service: 'wfs',
            outputFormat: ''
          }
        })
        .then(() => {
          EventBus.$emit('showSuccessMessage', 'Сохранено')
          this.refresh()
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'Ошибка')
        })
    },
    remove () {
      let { layer } = this.feature
      let formatWFS = new WFS()
      let gml = new GML({
        featureNS: layer._ds.targetNamespace,
        featurePrefix: layer._ds.targetPrefix,
        featureType: layer._ds.typeName,
        srsName: 'EPSG:3857'
      })
      let node = formatWFS.writeTransaction(null, null, [this.feature], gml)
      let s = new XMLSerializer()
      let value = s.serializeToString(node)
      this.$axios
        .post(window.location.origin + '/geoserver/wfs', value, {
          headers: {
            'Content-Type': 'text/xml'
          },
          params: {
            service: 'wfs',
            outputFormat: ''
          }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', 'Объект удален')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    updateShowBackBtn (v) {
      this.showBackBtn = v
    }
  },
  computed: {
    actions () {
      return [OPEN_ISSUES, SHARE, EDIT, REMOVE]
        .filter(action => this.isAuthenticated || !action.onlyAuthenticated)
    },
    name () {
      return this.feature.values_['dName'] || this.feature.layer.name
    },
    showDb () {
      return this.infoFromDb && this.infoFromDb.some(el => el.props.length)
    }
  }
}
</script>
