<template>
  <file-viewer
    v-if="fileToken"
    ref="fileViewer"
    :storage-url="file => `files/get-user-file/${file.token}`"
    @close="toDrive"
  />
</template>

<script>
import FileViewer from './FileViewer'
import { EventBus } from '@/event-bus'

export default {
  name: 'SharedFilePage',
  data: () => ({
    fileToken: ''
  }),
  components: { FileViewer },
  mounted () {
    this.$axios.get(`files/get-file-entity/${this.fileToken}`)
      .then(res => this.$refs.fileViewer.open(res.data))
      .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
  },
  methods: {
    toDrive () {
      this.$router.push('/drive')
    }
  },
  watch: {
    '$route.params.token': {
      handler: function (value) {
        if (value) {
          this.fileToken = value
        } else {
          this.toDrive()
        }
      },
      immediate: true
    }
  }
}
</script>
