<template>
  <div :style="widget ? {marginBottom: '46px'} : {}" class="et-viewer">
    <div
      v-if="!widget && isAuthenticated"
      :style="{width: fullScreenMode ? 'calc(100% - 130px)' : '100%'}"
      class="d-flex align-center px-2"
      style="height: 50px"
    >
      <v-btn outlined @click="openEtAddRowDialog">
        {{ $t('addRow') }}
      </v-btn>
      <v-spacer/>
      <v-tooltip bottom color="black">
        <template v-slot:activator="{on}">
          <v-btn v-show="showResetFilterButton" class="ml-2" outlined @click="resetFilter" v-on="on">
            <v-icon small>mdi-filter-remove</v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('resetFilter') }}</span>
        </template>
      </v-tooltip>
    </div>
    <v-data-table
      v-if="filteredHeaders"
      id="et-table"
      :headers="filteredHeaders"
      :items="table.body"
      :loading-text="$t('loading')"
      :no-data-text="$t('noData')"
      :no-results-text="$t('noResults')"
      :style="{height: `calc(100% - ${ isAuthenticated ? '96' : '46' }px)`}"
      disable-filtering
      disable-pagination
      disable-sort
      fixed-header
      hide-default-footer
      hide-default-header
    >
      <template v-slot:header="{ props }">
        <thead>
        <tr>
          <th v-for="header in filteredHeaders" class="pb-1 px-1" style="vertical-align: baseline">
            <div class="d-flex flex-column align-start justify-start flex-grow-1">
              <div
                class="et-table-header-text d-flex v-btn pointer"
                @click="e => setDirection(header, e)"
              >
                <div class="bold">
                  {{ header.fieldName }}
                  <v-icon
                    class="ml-2"
                  >
                    {{ getDirectionIcon(header) }}
                  </v-icon>
                </div>
                <div v-if="sortingConfig.find(el => el.index > 1)">
                  {{ getSortingIndex(header) }}
                </div>
              </div>
              <div v-if="filter.length > 0" style="width: 100%">
                <component
                  :is="getFilterComponent(header)"
                  :filter="filter"
                  :getIndexOfFilter="getIndexOfFilter"
                  :header="header"
                  :tableId="et.id"
                  @update="() => {updateBody()}"
                />
              </div>
            </div>
          </th>
          <th v-if="layers && layers.length"/>
        </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <et-viewer-item
          :headers="filteredHeaders"
          :item="item"
          :rules="et.rules"
          @openEtiItemDialog="openEtItemDialog"
        >
          <template v-slot:additional-column>
            <td v-if="layers && layers.length">
              <v-btn icon @click="e => openFeature(e, item)">
                <v-icon>map</v-icon>
              </v-btn>
            </td>
          </template>
        </et-viewer-item>
      </template>
    </v-data-table>
    <d-pagination
      :items-per-page-list="etConst.PAGINATION_ITEMS_PER_PAGE"
      :pagination="pagination"
      :widget="widget"
      class="d-pagination"
      @openPage="openPage"
    />
    <et-edit-query-dialog
      v-show="setAbility('SYSTEM_ADMIN')"
      ref="etEditSelectDialog"
      @save="onEditQuery"
    />
    <et-item-dialog
      ref="etItemDialog"
      @tableUpdated="updateBody"
    />
    <et-view-settings-dialog
      ref="etViewSettingsDialog"
      @save="onEditQuery"
    />
    <layer-poi-dialog
      ref="layerPoiDialog"
      actions-toolbar
      map-only-mode
      map-widget
    />
    <wms-feature-dialog
      ref="wmsFeatureDialog"
    />
    <et-import-dialog
      ref="etImportDialog"
      @updateTable="updateBody"
    />
    <et-create-dialog
      ref="etCreateDialog"
      @save="onSaveCopy"
    />
    <et-add-row-dialog
      ref="etAddRowDialog"
      @save="updateBody"
    />
    <et-to-layer-dialog
      ref="etToLayerDlg"
      :external-table="et"
    />
    <features-by-et-item-dialog
      ref="featuresByEtItemDialog"
    />
  </div>
</template>
<script>

import EtEditQueryDialog from '@/components/et/EtEditQueryDialog.vue'
import { EventBus } from '@/event-bus'
import DPagination from '@/components/utils/DPagination.vue'
import messages from '@/componet-locale/et/messages'
import EtItemDialog from '@/components/et/EtItemDialog.vue'
import {
  etConst,
  getDirection,
  getDirectionIcon,
  getFilterFromHeaders,
  getSortingConfigFromHeaders
} from '@/components/et/utils/utlis'
import StringHeaderFilter from '@/components/et/header-filters/StringHeaderFilter.vue'
import BooleanHeaderFilter from '@/components/et/header-filters/BooleanHeaderFilter.vue'
import ListHeaderFilter from '@/components/et/header-filters/ListHeaderFilter.vue'
import EtViewSettingsDialog from '@/components/et/view-settings/EtViewSettingsDialog.vue'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog.vue'
import EtImportDialog from '@/components/et/EtImportDialog.vue'
import AbstractDataDetails from '@/components/utils/AbstractDataDetails.vue'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog.vue'
import EtCreateDialog from '@/components/et/EtCreateDialog.vue'
import EtAddRowDialog from '@/components/et/EtAddRowDialog.vue'
import DefaultHeaderFilter from '@/components/et/header-filters/DefaultHeaderFilter.vue'
import DateHeaderFilter from '@/components/et/header-filters/DateHeaderFilter.vue'
import moment from 'moment'
import EtToLayerDialog from '@/components/et/EtToLayerDialog.vue'
import WmsFeatureDialog from '@/components/map/geoserver/WmsFeatureDialog.vue'
import FeaturesByEtItemDialog from '@/components/et/utils/FeaturesByEtItemDialog.vue'
import EtViewerItem from '@/components/et/EtViewerItem.vue'

export default {
  name: 'EtViewer',
  components: {
    FeaturesByEtItemDialog,
    WmsFeatureDialog,
    EtToLayerDialog,
    EtCreateDialog,
    AbstractDataDetailsDialog,
    AbstractDataDetails,
    EtImportDialog,
    LayerPoiDialog,
    EtViewSettingsDialog,
    EtItemDialog,
    DPagination,
    EtEditQueryDialog,
    EtAddRowDialog,
    EtViewerItem
  },
  i18n: { messages },
  props: {
    et: Object,
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    widget: {
      type: Boolean,
      default: false
    },
    fullScreenMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    table: Object,
    pagination: { ...etConst.DEFAULT_PAGINATION },
    sortingConfig: [],
    filter: [],
    primaryTableName: null,
    mainPredicate: 'AND',
    lockQuery: false,
    layers: []
  }),
  mounted () {
    EventBus.$on('openEtEditQueryDialog', this.openEtEditQueryDialog)
    EventBus.$on('openEtViewSettingsDialog', this.openEtRulesDialog)
    EventBus.$on('openEtImportDialog', this.openEtImportDialog)
    EventBus.$on('openEtToLayerDlg', this.openEtToLayerDlg)
    EventBus.$on('exportEt', this.exportXlsx)
    EventBus.$on('makeCopy', this.makeCopy)
    this.init()
  },
  methods: {
    init () {
      this.filter = []
      this.sortingConfig = []
      this.pagination = { ...etConst.DEFAULT_PAGINATION }
      this.mainPredicate = 'AND'
      this.getRelatedLayers()
      this.updateBody()
      this.resetFilter()
    },
    updateBody () {
      if (this.lockQuery) {
        return
      }
      this.lockQuery = true
      const validPage = this.getValidPage()
      const sortingConfig = getSortingConfigFromHeaders(this.sortingConfig)
      this.$axios
        .post(this.isAuthenticated ? 'et/query/select' : 'public-data/et-select', {
          tableId: this.et.id,
          pageable: {
            page: validPage,
            rowPerPage: this.pagination.itemsPerPage
          },
          mainPredicate: this.mainPredicate,
          sortingConfig: sortingConfig,
          conditions: this.filter
        })
        .then(res => {
          this.table = {
            headers: res.data.headers,
            body: res.data.body
          }
          this.pagination.itemsCount = res.data.itemsCount
          this.pagination.page = validPage
        })
        .catch(e => {
          EventBus.$emit('showErrorMessage', this.$t(e.data.message || 'error'))
        })
        .finally(() => {
          this.lockQuery = false
        })
    },
    exportXlsx () {
      const validPage = this.getValidPage()
      const sortingConfig = getSortingConfigFromHeaders(this.sortingConfig)
      this.$axios
        .post('et/export/xlsx', {
            tableId: this.et.id,
            pageable: {
              page: validPage,
              rowPerPage: this.pagination.itemsPerPage
            },
            mainPredicate: this.mainPredicate,
            sortingConfig: sortingConfig,
            conditions: this.filter
          },
          {
            responseType: 'arraybuffer'
          })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          const name = this.et.name + ' ' + new Date().toLocaleDateString() || 'external-table'
          link.download = `${name}.xlsx`
          document.body.appendChild(link)
          link.click()
        })
        .catch((e) => EventBus.$emit('showErrorMessage', this.$t('exportError')))
    },
    async openFeature (e, item) {
      e.stopPropagation()

      const items = []

      for (let layer of this.layers) {
        if (layer.layer.type === 'LAYER_POI') {
          const body = {
            page: 0,
            rowsPerPage: 15,
            layerPoiCriteria: {
              fieldId: layer.lpSearchField.id,
              dataType: layer.lpSearchField.type,
              value: item[layer.header.alias] || ''
            }
          }
          await this.$axios
            .post('layer-poi/filter', body, {
              params: {
                layerId: layer.layer.id,
                templateId: layer.lpTemplate.id
              }
            })
            .then(res => res.data.content)
            .then(features => {
              if (features.length > 0) items.push({ layer: layer.layer, features })
            })
        }

        if (layer.layer.type === 'WMS') {
          await this.$axios.get(window.location.origin + '/geoserver/wfs', {
            params: {
              service: 'wfs',
              version: '2.0.0',
              request: 'GetFeature',
              outputFormat: 'application/json',
              typeName: layer.layer.layerId,
              cql_filter: `${layer.wfsPropertyName}='${item[layer.header.alias]}'`
            }
          })
            .then(res => {
              const features = res.data.features.map(el => ({ ...el, layer: layer.layer }))
              if (features.length > 0) items.push({ layer: layer.layer, features })
            })
        }
      }

      this.$refs.featuresByEtItemDialog.open(items)
    },
    makeCopy () {
      if (!this.$refs.etCreateDialog) return
      let item = JSON.parse(JSON.stringify(this.et))
      item = {
        provider: item.provider,
        description: item.description,
        status: item.status,
        keywords: item.keywords,
        categoryList: item.categoryList,
        qfrom: item.qfrom,
        qwhere: item.qwhere,
        qgroupby: item.qgroupby,
        qorderby: item.qorderby,
        primaryTableName: item.primaryTableName,
        headers: item.headers
      }
      item.headers.forEach(el => delete el.id)
      this.$refs.etCreateDialog.open(item)
    },
    onSaveCopy () {
      EventBus.$emit('etCopySaved')
      this.$router.back()
    },
    getFilterComponent (header) {
      switch (header.fieldType) {
        case 'BOOLEAN':
          return BooleanHeaderFilter
        case 'LIST':
          return ListHeaderFilter
        case 'DATE':
          return DateHeaderFilter
        case 'STRING':
          return StringHeaderFilter
        default:
          return DefaultHeaderFilter
      }
    },
    onEditQuery () {
      this.$emit('etUpdated')
    },
    openPage (page) {
      this.pagination.page = page
    },
    openEtItemDialog (item) {
      this.$refs.etItemDialog.open(this.et, item)
    },
    openEtEditQueryDialog () {
      if (this.$refs.etEditSelectDialog) {
        this.$refs.etEditSelectDialog.open(this.et)
      }
    },
    openEtRulesDialog () {
      if (this.$refs.etViewSettingsDialog) {
        this.$refs.etViewSettingsDialog.open(this.et.id)
      }
    },
    openEtImportDialog () {
      if (this.$refs.etImportDialog) {
        this.$refs.etImportDialog.open(this.et)
      }
    },
    openEtToLayerDlg () {
      if (this.$refs.etToLayerDlg) {
        this.$refs.etToLayerDlg.open()
      }
    },
    getIndexOfFilter (alias) {
      if (this.filter) {
        return this.filter.indexOf(this.filter.find(el => el.alias === alias))
      }
    },
    getValidPage () {
      const pageCount = Math.ceil(this.pagination.itemsCount / this.pagination.itemsPerPage)
      if (this.pagination.page > pageCount) {
        return pageCount === 0 ? 1 : pageCount
      }
      return this.pagination.page
    },
    getDirectionIcon (header) {
      return this.sortingConfig.find(el => el.key === header.key)
        ? getDirectionIcon(this.sortingConfig.find(el => el.key === header.key).direction)
        : ''
    },
    getSortingIndex (header) {
      return this.sortingConfig.find(el => el.key === header.key && el.index > 0)
        ? this.sortingConfig.find(el => el.key === header.key).index
        : ''
    },
    setDirection (header, e) {
      if (!e.shiftKey) {
        this.sortingConfig.forEach((el) => {
          if (el.key === header.key) {
            el.direction = getDirection(el.direction)
            el.index = 1
          } else {
            el.direction = 'NULL'
            el.index = 0
          }
        })
      } else {
        this.sortingConfig.forEach((el) => {
          if (el.key === header.key) {
            el.direction = getDirection(el.direction)
          }
          if (el.key === header.key && el.index === 0) {
            el.index = Math.max(...this.sortingConfig.map(el => el.index)) + 1
          }
        })
      }
    },
    openEtAddRowDialog () {
      this.$refs.etAddRowDialog.open(this.et.id)
    },
    getRelatedLayers () {
      if (!this.isAuthenticated) return
      this.$axios
        .get('/et/get-related-layers', {
          params: {
            etId: this.et.id
          }
        })
        .then(response => {
          this.layers = response.data
        })
    },
    resetFilter () {
      this.sortingConfig = getSortingConfigFromHeaders(this.et.headers)
      this.filter = getFilterFromHeaders(this.et.headers)
    },
    reloadTable () {
      this.table = {}
      this.updateBody()
    }
  },
  computed: {
    etConst () {
      return etConst
    },
    filteredHeaders () {
      return this.et.headers
        .filter(el => !el.excludeFromTable)
        .sort((prev, next) => (prev.showIndex - next.showIndex))
    },
    moment () {
      return moment
    },
    showResetFilterButton () {
      return this.sortingConfig.some(el => el.direction !== 'NULL')
        || this.filter.some(el => el.value.length && el.value[0])
    }
  },
  watch: {
    et: {
      handler () {
        this.init()
      },
      deep: true
    },
    pagination: {
      handler () {
        this.updateBody()
      },
      deep: true
    },
    sortingConfig: {
      handler () {
        this.updateBody()
      },
      deep: true
    },
    filter: {
      handler () {
        this.updateBody()
      },
      deep: true
    },
    mainPredicate () {
      this.updateBody()
    }
  }
}
</script>

<style>

.et-viewer {
  height: 100%;
  position: relative;
}

#et-table {
  overflow: auto;
  scrollbar-gutter: stable;
}

#et-table tbody > tr > td:not(:first-child),
#et-table thead > tr > th:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}

#et-table th {
  font-weight: normal;
}

#et-table .v-data-table__wrapper {
  height: 100%;
  overflow-y: scroll;
}

.et-table-header-text {
  flex-wrap: nowrap;
  width: fit-content;
}

.et-viewer .d-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

</style>
